import React from 'react';

export interface ICustomEventSource {
	source: any;
	onMessage: (cb: (message: any) => void) => void;
	onError: (cb: (message: any) => void) => void;
	onOpen: (cb: () => void) => void;

	beforeUnload?: (cb: () => void) => void;
	close: () => void;
}

export interface IServiceProvider {
	apiBaseUrl?: string;
	interceptors?: {
		success: (
			response: Response,
			data: any,
			input: RequestInfo,
			init?: RequestInit,
			disableAlert?: boolean
		) => void;
		error: (
			e: any,
			input: RequestInfo,
			init?: RequestInit,
			disableAlert?: boolean
		) => void;
	}[];
	getUserHeadersSync?: () => Record<string, string>;
	getUserHeaders?: () => Promise<Record<string, string>>;
	processAuthError?: () => Promise<void>;
	eventSourceConstructor: new (
		url: string,
		headers?: Record<string, any>
	) => ICustomEventSource;
}

class DumbEventSource implements ICustomEventSource {
	source: any = null;

	onMessage = () => {};
	onError = () => {};
	onOpen = () => {};
	close = () => {};
}

const defaultValue = {
	apiBaseUrl: '',
	eventSourceConstructor: DumbEventSource,
	interceptors: []
};

export const ServiceContext =
	React.createContext<IServiceProvider>(defaultValue);

export const ServiceProvider: React.FC<{
	value: Partial<IServiceProvider>;
	children: React.ReactNode;
}> = ({ value, children }) => {
	return (
		<ServiceContext.Provider
			value={{
				...defaultValue,
				...value
			}}
		>
			{children}
		</ServiceContext.Provider>
	);
};
