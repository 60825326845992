import React, {
	FunctionComponent,
	createContext,
	useState,
	useCallback
} from 'react';

export interface ModalHandler {
	onClose(): void;
	id: string;
}

export interface BaseModalProps {
	className?: string;
	bodyClassName?: string;
	backdropClassName?: string;
	contentClassName?: string;
	onClose?: () => void;
}

export interface Modal<T> {
	id: string;
	component: FunctionComponent<T & BaseModalProps>;
	props: T;
}

interface ModalContextType {
	modals: Modal<BaseModalProps>[];

	openModal(
		component: FunctionComponent<any>,
		props?: any
	): ModalHandler;
	closeModal(id: string): void;
}

// TODO: fix the context
export const ModalContext = createContext<ModalContextType>({
	modals: [],
	openModal: () => ({ id: '', onClose: () => {} }),
	closeModal: () => {}
});

export const ModalProvider: React.FC<{
	children: React.ReactNode;
	render: React.FC<Modal<BaseModalProps>>;
}> = ({ children, render }) => {
	const [modals, setModals] = useState<Modal<BaseModalProps>[]>([]);

	const closeModal = useCallback((id: string) => {
		setModals((value) => value.filter((item) => item.id !== id));
	}, []);

	const openModal = useCallback(function open<T>(
		component: React.FC<BaseModalProps>,
		props?: T
	) {
		const id = (Math.random() + 1).toString(36).substring(7);

		const onClose = () => {
			closeModal(id);
		};

		setModals((modals) =>
			modals.concat([{ id, component, props: { ...(props || {}), onClose } }])
		);

		return {
			id,
			onClose
		};
	},
	[]);

	return (
		<ModalContext.Provider
			value={{
				modals,
				openModal,
				closeModal
			}}
		>
			{children}

			{modals.map(render)}
		</ModalContext.Provider>
	);
};
