import { useCallback } from 'react';
import { toast, ToastOptions } from 'react-toastify';

export const TOAST_CONFIG: ToastOptions = {
	position: 'top-center',
	autoClose: 5000,
	hideProgressBar: true,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	progress: undefined,
	theme: 'light',
	icon: undefined
};

export type UseToasterHook = () => {
	success: (content: string) => void;
	failed: (content: string) => void;
	info: (content: string) => void;
};

export const useToaster: UseToasterHook = () => {
	const success = useCallback((content: string): void => {
		toast.success(content, TOAST_CONFIG);
	}, []);

	const failed = useCallback((content: string): void => {
		toast.error(content, TOAST_CONFIG);
	}, []);

	const info = useCallback((content: string): void => {
		toast.info(content, TOAST_CONFIG);
	}, []);

	return {
		success,
		failed,
		info
	};
};
