import styles from './loader.module.scss';

export interface ILoaderProps {
  color?: string;
  className?: string;
}

export const Loader: React.FC<ILoaderProps> = ({ className }) => (
  <span className={`${className} ${styles.loader}`}></span>
);
