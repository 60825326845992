export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID as string;

interface PageEventProps {
	event: string;
	page: string;
}

interface AnyEventProps {
	event: string;
	data: unknown;
}

export class Gtag {
	public async getClientId(): Promise<string | undefined> {
		return new Promise((resolve) => {
			window.gtag('get', 'G-8E9D5ZZ64Z', 'client_id', (clientId: unknown) => {
				resolve(clientId as string | undefined);
			});
		});
	}

	public setUserId(id: string): void {
		window.gtag('config', 'G-8E9D5ZZ64Z', { user_id: id });
		//@ts-ignore
		window &&
			//@ts-ignore
			window.dataLayer &&
			//@ts-ignore
			window.dataLayer.push({
				event: 'login',
				userId: id //this number must be replaced with an actual User ID
			});
	}

	public setConversationId(id: string | undefined): void {
		if (typeof window !== 'undefined' && window.gtag) {
			window.gtag('config', 'G-8E9D5ZZ64Z', { conversation_id: id });
		}
	}

	public pageView = (url: string) => {
		const pageEvent: PageEventProps = {
			event: 'page_view',
			page: url
		};
		//@ts-ignore
		window && window.dataLayer && window.dataLayer.push(pageEvent);
	};

	public event = (eventName: string, data?: Record<string, unknown>) => {
		const pageEvent: AnyEventProps = {
			event: eventName,
			data
		};
		//@ts-ignore
		window && window.dataLayer && window.dataLayer.push(pageEvent);
	};
}
