import { useCallback } from 'react';
import postHogLibrary, { Properties } from 'posthog-js';
import { useAnalyticsApi } from '../analytics-api';
// import { useUserService } from '../user/user.service';
// import { useChatStorage } from '../chat-storage/chat-storage.service';
import { Gtag } from './gtag.utils';

export type AnalyticsEventPriority = 'conversion' | 'regular';

export type UseAnalyticsHook = () => {
	capturePageView: (path: string) => void;
	trackEvent: (
		name: string,
		data: Record<string, unknown>,
		priority?: AnalyticsEventPriority
	) => void;
};

export const useAnalytics: UseAnalyticsHook = () => {
	const { trackEvent: trackEventApi } = useAnalyticsApi();
	// const { userData } = useUserService();

	// useEffect(() => {
	// 	if (userData && userData.userId && typeof window !== 'undefined') {
	// 		const gtag = new Gtag();
	// 		gtag.setUserId(userData.userId);
	// 	}
	// }, [userData]);

	const trackEvent = useCallback(
		(
			eventName: string,
			eventData: Record<string, unknown>,
			priority: AnalyticsEventPriority = 'regular'
		): void => {
			if (eventName) {
				const data = {
					data: eventData,
					// deviceId: userData.fingerPrintId,
					// userId: userData.userId,
					// sessionId: streamParams.chatId,
					clientTimestamp: new Date().toISOString()
				};

				try {
					trackEventApi(eventName, data);
				} catch (error) {
					console.error(error);
				}

				postHogLibrary.capture(eventName, {
					data: eventData,
					userAgent: window.navigator.userAgent
				} as Properties);

				if (priority === 'conversion') {
					const gtag = new Gtag();
					gtag.event(eventName, eventData || undefined);
				}
			}
		},
		[trackEventApi]
	);

	const capturePageView = useCallback((url: string): void => {
		if (typeof window !== 'undefined') {
			const gtag = new Gtag();
			gtag.pageView(url);
		}
	}, []);

	return {
		trackEvent,
		capturePageView
	};
};
