import { useCallback, useContext } from 'react';
import { Observable, from } from 'rxjs';
import { ServiceContext } from '../../platform/providers/service.provider';

export type UseHttpHook = () => {
	makeRequest<T>(input: RequestInfo, init?: RequestInit): Observable<T>;
};

export const useHttp: UseHttpHook = () => {
	const { apiBaseUrl, getUserHeaders } = useContext(ServiceContext);

	const makeRequest = useCallback(function makeRequest<T>(
		input: RequestInfo,
		init: RequestInit = {}
	): Observable<T> {
		const headers = getUserHeaders ? getUserHeaders() : {};

		return from(
			fetch(`${apiBaseUrl || ''}${input}`, {
				...init,
				headers: {
					Accept: 'application/json',
					'Client-Date': new Date().toUTCString(),
					...headers,
					...(init.headers ? init.headers : {})
				}
			}).then((result) => result.json() as T)
		);
	},
	[]);

	return {
		makeRequest
	};
};
