import { useCallback } from 'react';
import { Observable } from 'rxjs';
import { useHttp } from '../http/http.service';

export type UseAnalyticsApiHook = () => {
	trackEvent: (name: string, data: unknown) => Observable<void>;
};

export const useAnalyticsApi: UseAnalyticsApiHook = () => {
	const { makeRequest } = useHttp();

	const trackEvent = useCallback(
		(name: string, data: unknown) => {
			return makeRequest<void>('/api/analytics/event', {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'key-code': '70883935553640088071841071679424'
				},
				body: JSON.stringify({ eventName: name, data })
			});
		},
		[makeRequest]
	);

	return {
		trackEvent
	};
};
