import { ICustomEventSource } from 'ollie-common/platform/providers/service.provider';
import ReconnectingEventSource from 'reconnecting-eventsource';

export class NextEventSource implements ICustomEventSource {
  source: ReconnectingEventSource;

  constructor(private url: string) {
    this.source = new ReconnectingEventSource(url, {});
  }

  onMessage(cb: (msg: string) => void) {
    this.source.onmessage = (message: any) => {
      cb(message.data);
    };
  }

  onOpen(cb: () => void) {
    this.source.onopen = () => {
      cb();
    };
  }

  onError(cb: (e: Event) => void) {
    this.source.onerror = (e: Event) => {
      cb(e);
    };
  }

  beforeUnload?(cb: () => void) {
    window.addEventListener('beforeunload', () => {
      cb();
    });
  }

  close() {
    this.source.close();
  }
}
